import '@fontsource/darker-grotesque';

import { Icon, createIcon, extendTheme } from '@chakra-ui/react';
import { getFontsByPartner, getThemeByPartner } from '../utils/partnerTheme';

import React from 'react';
import { theme as proTheme } from '@chakra-ui/pro-theme';

// const fonts = {
//   body: '"Lota Grotesque", sans-serif',
//   heading: '"Lota Grotesque", sans-serif',
//   mono: '"Lota Grotesque", sans-serif',
// };

const breakpoints = ['40em', '52em', '64em'];

const yellowTheme = {
  colors: {
    ...proTheme.colors,
    primary: '#ffcb05',
    secondary: '#FFE7A7',
    background: '#FFFAED',
    title: '#210124',
    subtitle: '#FFFFFF',
    authBtn: '#000000',
    icon: '#7C7B7B',
    iconBg: '#FFFAED',
    card1: '#FFE7A7',
    card2: '#ffcb05',
    card3: '#C5C5C5',
    card4: '#665B49',
  },
  fonts: {
    heading: `'MTNBrighterSans-ExtraBold', sans-serif`,
    body: `'MTNBrighterSans-Regular', sans-serif`,
  },
};
const redTheme = {
  colors: {
    ...proTheme.colors,
    primary: '#CC0704',
    secondary: '#ffdfdf',
    background: '#ffeaea',
    title: '#FFFFFF',
    subtitle: '#ffd7db',
    authBtn: '#FFFFFF',
    icon: '#d60f23',
    iconBg: '#ffaabf',
    card1: '#F2212180',
    card2: '#F22121CC',
    card3: '#ffb7d3',
    card4: '#efbdbd',
  },
  fonts: {
    heading: `'Lota Grotesque', sans-serif`,
    body: `'Lota Grotesque', sans-serif`,
  },
};
const defaultTheme = {
  colors: {
    ...proTheme.colors,
    primary: '#0F61D6',
    secondary: '#DFF7FF',
    background: '#EAF3FF',
    title: '#FFFFFF',
    subtitle: '#D7E7FF',
    authBtn: '#FFFFFF',
    icon: '#0F61D6',
    iconBg: '#AAE8FF',
    card1: '#0F61D640',
    card2: '#0F61D699',
    card3: '#B7ECFF',
    card4: '#BDD2EF',
  },
  fonts: {
    heading: `'Lota Grotesque', sans-serif`,
    body: `'Lota Grotesque', sans-serif`,
  },
};

const customIcons = {
  logo: {
    path: (
      <path
        fill="#0C47F4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.46338 0.64645H0V36.9528L7.70724 35.3547V23.9318C9.46334 26.1773 11.9024 27.2512 15.0731 27.2512C18.3902 27.2512 21.3658 26.0308 23.8049 23.6388C26.5365 21.0027 28 17.6345 28 13.8756C28 9.77502 26.4391 6.26026 23.4147 3.57542C21.1219 1.52512 18.3902 0.499989 15.2195 0.499989C12.0975 0.499989 9.756 1.32987 7.46338 3.57542V0.64645ZM20.2929 13.9245C20.2929 17.4393 17.4636 20.417 13.805 20.417C10.1953 20.417 7.41484 17.5857 7.41484 13.8269C7.41484 10.2144 10.1953 7.48075 13.5124 7.48075C17.4636 7.48075 20.2929 10.2632 20.2929 13.9245Z"
      />
    ),
    // If the icon's viewBox is `0 0 24 24`, you can ignore `viewBox`
    viewBox: '0 0 28 37',
  },
  dashboard: createIcon({
    path: (
      <svg fill="none">
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.25195 14.0001C5.14004 17.4506 8.27224 20.0001 11.9999 20.0001C16.4182 20.0001 19.9999 16.4184 19.9999 12.0001C19.9999 7.9205 16.9462 4.55411 12.9999 4.06201V14.0001H4.25195Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.06055 10.0121L11.0002 11.6V3C6.91637 3 3.54727 6.05992 3.06055 10.0121Z"
          fill="currentColor"
        />
      </svg>
    ),
    viewBox: '0 0 20 20',
  }),
  orders: createIcon({
    path: (
      <svg fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 9.67469L10.8803 13.647C10.9543 13.6898 11.032 13.7207 11.1111 13.7403V21.4444L4.4907 17.5264C4.18655 17.3465 4 17.0193 4 16.6659V9.67469ZM20 9.56909V16.6659C20 17.0193 19.8134 17.3465 19.5093 17.5264L12.8889 21.4444V13.6728C12.905 13.6647 12.921 13.6562 12.9368 13.647L20 9.56909Z"
          fill="currentColor"
        />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.21631 7.74659C4.30035 7.64046 4.40642 7.55077 4.53009 7.4849L11.5301 3.75655C11.8239 3.60003 12.1764 3.60003 12.4703 3.75655L19.4703 7.4849C19.5656 7.53567 19.6505 7.60061 19.7229 7.67631L12.0481 12.1073C11.9977 12.1365 11.9511 12.1698 11.9087 12.2066C11.8663 12.1698 11.8198 12.1365 11.7693 12.1073L4.21631 7.74659Z"
          fill="currentColor"
        />
      </svg>
    ),
    viewBox: '0 0 20 22',
  }),
  warranty: createIcon({
    path: (
      <svg fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 6.5C9 5.67157 9.67157 5 10.5 5H19.5C20.3284 5 21 5.67157 21 6.5C21 7.32843 20.3284 8 19.5 8H10.5C9.67157 8 9 7.32843 9 6.5ZM9 11.5C9 10.6716 9.67157 10 10.5 10H19.5C20.3284 10 21 10.6716 21 11.5C21 12.3284 20.3284 13 19.5 13H10.5C9.67157 13 9 12.3284 9 11.5ZM10.5 15C9.67157 15 9 15.6716 9 16.5C9 17.3284 9.67157 18 10.5 18H19.5C20.3284 18 21 17.3284 21 16.5C21 15.6716 20.3284 15 19.5 15H10.5Z"
          fill="currentColor"
        />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 8C6.32843 8 7 7.32843 7 6.5C7 5.67157 6.32843 5 5.5 5C4.67157 5 4 5.67157 4 6.5C4 7.32843 4.67157 8 5.5 8ZM5.5 13C6.32843 13 7 12.3284 7 11.5C7 10.6716 6.32843 10 5.5 10C4.67157 10 4 10.6716 4 11.5C4 12.3284 4.67157 13 5.5 13ZM7 16.5C7 17.3284 6.32843 18 5.5 18C4.67157 18 4 17.3284 4 16.5C4 15.6716 4.67157 15 5.5 15C6.32843 15 7 15.6716 7 16.5Z"
          fill="currentColor"
        />
      </svg>
    ),
    viewBox: '0 0 20 22',
  }),
  chat: createIcon({
    path: (
      <path
        fill="currentColor"
        d="M0.375 0.6875V11.6875H3.125V15.1895L7.48633 11.6875H14.125V0.6875H0.375ZM1.75 2.0625H12.75V10.3125H7.01367L4.5 12.3105V10.3125H1.75V2.0625ZM15.5 3.4375V4.8125H18.25V13.0625H15.5V15.0605L12.9863 13.0625H7.83008L6.11133 14.4375H12.5137L16.875 17.9395V14.4375H19.625V3.4375H15.5Z"
      />
    ),
    viewBox: '0 0 20 18',
  }),
  logout: createIcon({
    path: (
      <path
        fill="currentColor"
        d="M7,14 L2,14 L2,2 L7,2 C7.55,2 8,1.55 8,1 C8,0.45 7.55,0 7,0 L1,0 C0.45,0 0,0.45 0,1 L0,15 C0,15.55 0.45,16 1,16 L7,16 C7.55,16 8,15.55 8,15 C8,14.45 7.55,14 7,14 Z M15.71,7.29 L12.71,4.29 C12.53,4.11 12.28,4 12,4 C11.45,4 11,4.45 11,5 C11,5.28 11.11,5.53 11.29,5.71 L12.59,7 L6,7 C5.45,7 5,7.45 5,8 C5,8.55 5.45,9 6,9 L12.59,9 L11.3,10.29 C11.11,10.47 11,10.72 11,11 C11,11.55 11.45,12 12,12 C12.28,12 12.53,11.89 12.71,11.71 L15.71,8.71 C15.89,8.53 16,8.28 16,8 C16,7.72 15.89,7.47 15.71,7.29 Z"
      />
    ),
    viewBox: '0 0 16 16',
  }),
  more: createIcon({
    path: (
      <path
        fill="currentColor"
        d="M2 0C.895 0 0 .895 0 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm12 0c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zM8 0C6.895 0 6 .895 6 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
      />
    ),
    viewBox: '0 0 16 16',
  }),
  cards: createIcon({
    path: (
      <svg>
        <g>
          <g>
            <rect x="49.2" y="225.488" width="81.9" height="16.4" />
          </g>
        </g>
        <g>
          <g>
            <rect x="49.2" y="274.688" width="122.9" height="16.4" />
          </g>
        </g>
        <g>
          <g>
            <rect x="49.2" y="323.888" width="295" height="16.4" />
          </g>
        </g>
        <g>
          <g>
            <rect x="49.2" y="372.988" width="49.2" height="16.4" />
          </g>
        </g>
        <g>
          <g>
            <rect x="131.1" y="372.988" width="49.2" height="16.4" />
          </g>
        </g>
        <g>
          <g>
            <rect x="213.1" y="372.988" width="49.2" height="16.4" />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M336,217.288c-6,0-11.5,1.7-16.3,4.5c-5-2.9-10.6-4.5-16.5-4.5c-18.1,0-32.8,14.7-32.8,32.8c0,18.1,14.7,32.8,32.8,32.8
              c5.9,0,11.5-1.6,16.5-4.5c4.8,2.8,10.3,4.5,16.3,4.5c18.1,0,32.8-14.7,32.8-32.8S354.1,217.288,336,217.288z M307.4,265.888
              c-1.4,0.4-2.8,0.6-4.2,0.6c-9,0-16.4-7.4-16.4-16.4c0-9,7.4-16.4,16.4-16.4c1.4,0,2.9,0.3,4.2,0.6c-2.6,4.7-4.2,10-4.2,15.8
              C303.2,255.888,304.8,261.188,307.4,265.888z M336,266.488c-9,0-16.4-7.4-16.4-16.4c0-9,7.4-16.4,16.4-16.4s16.4,7.4,16.4,16.4
              C352.4,259.088,345,266.488,336,266.488z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M490.9,179.388l-343.8-108.6c-2.4-0.8-21.6-7.1-30.8,16l-28.3,89.5H24.6c-13.6,0.1-24.6,11.1-24.6,24.6v213.1
              c0,13.6,11,24.6,24.6,24.6h360.6c8.6,0,16.1-4.4,20.5-11.1c0,0,11.1,3.1,13.6,3.1c10.8,0,20.2-6.9,23.4-17.2l64.2-203.2
              C508.9,203.988,510.3,184.588,490.9,179.388z M122,122.988l168.9,53.3H182l-69.9-22.1L122,122.988z M107.2,169.888l20.5,6.5h-22.5
              L107.2,169.888z M393.4,413.988h-0.1c0,4.5-3.7,8.2-8.2,8.2H24.6c-4.5,0-8.2-3.7-8.2-8.2v-213.1c0-4.5,3.7-8.2,8.2-8.2h360.6
              c4.5,0,8.2,3.7,8.2,8.2V413.988z M427.1,408.488c-1.3,4.2-6.1,6.7-10.3,5.3l-7.1-2.2v-146.1l56.9,18L427.1,408.488z
               M471.5,267.788l-61.8-19.5v-34.4l71.7,22.6L471.5,267.788z M491.3,205.288l-4.9,15.6l-77.1-24.3c-2.1-11.5-12.1-20.2-24.1-20.2
              h-39.9l-218.4-69l4.9-15.6c1.3-4.2,6-6.7,10.3-5.3l343.8,108.5C492.8,196.788,491.9,203.188,491.3,205.288z"
            />
          </g>
        </g>
      </svg>
    ),
    viewBox: '0 0 507.996 507.996',
  }),
  money: createIcon({
    path: (
      <svg>
        <g>
          <g>
            <g>
              <path d="M316.209,117.615h0.598C316.808,117.615,316.342,116.445,316.209,117.615z" />
              <path d="M171.747,117.615h0.598C172.212,116.445,171.747,117.615,171.747,117.615z" />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              d="M460.496,154.447c-3.357-5.359-8.599-9.09-14.761-10.506l-32.457-7.458l14.986-4.195
              c12.417-3.475,19.898-16.737,16.417-29.177c-3.476-12.417-16.736-19.896-29.178-16.417l-69.876,19.557
              c-1.819-3.31-4.406-6.143-7.522-8.251c14.181-26.391,34.612-71.176,34.612-90.443c0-4.699-4.442-8.335-9.051-7.406
              c-27.731,5.602-53.586,8.187-79.011,7.91c-26.134-0.287-58.659-3.533-79.095-7.893c-6.318-1.348-11.388,5.722-8.056,11.276
              c4.98,8.3,8.031,17.076,9.708,25.921c-25.649,0.786-52.248-2.322-72.38-12.389c-5.423-2.712-11.824,2.033-10.832,8
              c6.332,37.987,19.106,60.259,31.913,77.086h-0.284c-13.053,0-23.673,10.62-23.673,23.673c0,12.005,8.985,21.944,20.581,23.465
              c-8.453,14.158-20.525,27.591-33.209,41.687c-26.973,29.976-57.546,63.952-67.832,118.811c-4.922,26.249-4.758,50.81,0.489,73.002
              c4.898,20.718,14.227,39.471,27.726,55.737c17.919,21.592,42.538,38.345,73.172,49.797c27.595,10.315,59.198,15.767,91.394,15.767
              c32.196,0,63.8-5.452,91.394-15.767c30.634-11.452,55.253-28.206,73.172-49.797c13.499-16.266,22.828-35.018,27.726-55.737
              c5.246-22.192,5.411-46.754,0.489-73.002c-10.286-54.858-40.858-88.834-67.831-118.81c-9.157-10.177-17.869-19.88-25.173-29.728
              l91.079,20.926c9.649,2.217,19.931-2.004,25.261-10.342C465.272,172.11,465.301,162.115,460.496,154.447z M284.489,23.17
              c23.263,0.254,46.807-1.78,71.676-6.212c-4.56,18.643-19.203,51.801-33.092,77.06c-9.625,0.725-17.659,7.227-20.637,16.043H281.46
              c-0.474-1.021-0.999-2.14-1.584-3.383C273.39,92.89,259.892,64.2,259.892,39.792c0-4.692-4.431-8.33-9.037-7.408
              c-8.792,1.759-18.465,3.182-28.53,4.074c-1.055-6.304-2.692-12.618-5.072-18.828C237.553,20.772,263.124,22.935,284.489,23.17z
               M333.429,117.615v32.236c0,4.721-3.841,8.563-8.563,8.563s-8.563-3.841-8.563-8.563v-32.236c0-4.722,3.841-8.563,8.563-8.563
              S333.429,112.894,333.429,117.615z M141.612,44.107c18.888,6.447,42.123,9.306,67.216,8.424l14.974-0.946
              c7.063-0.648,14.229-1.581,21.448-2.802c2.356,23.374,13.034,47.392,19.522,61.278h-50.307h-15.894h-23.376
              c-0.169-0.265-0.351-0.525-0.556-0.773C162.002,94.085,149.179,76.5,141.612,44.107z M357.994,208.993
              c25.645,28.498,54.708,60.798,64.214,111.487c8.747,46.652,0.338,85.782-24.993,116.303
              c-31.234,37.635-88.407,60.105-152.937,60.105S122.574,474.419,91.34,436.784c-25.331-30.521-33.739-69.651-24.993-116.303
              c9.505-50.69,38.57-82.991,64.214-111.488c15.333-17.041,29.819-33.159,39.103-51.586h18.452c4.174,0,7.555-3.383,7.555-7.555
              c0-4.173-3.382-7.555-7.555-7.555h-32.488c-4.722,0-8.563-3.841-8.563-8.563c0-4.722,3.841-8.563,8.563-8.563h145.564v17.125
              h-77.064c-4.174,0-7.555,3.383-7.555,7.555c0,4.172,3.382,7.555,7.555,7.555h78.307c3.161,9.358,12.018,16.118,22.43,16.118
              c1.203,0,2.383-0.092,3.539-0.265C336.836,185.456,347.114,196.903,357.994,208.993z M348.54,121.127l71.035-19.881
              c4.498-1.261,9.298,1.449,10.554,5.938c1.258,4.499-1.447,9.297-5.938,10.553l-40.596,11.362
              c-0.331,0.093-0.647,0.213-0.954,0.346l-34.101-7.835V121.127z M448.779,168.929c-1.058,4.602-5.654,7.487-10.263,6.428
              l-90.451-20.782c0.31-1.527,0.474-3.107,0.474-4.725v-12.738l93.811,21.554C446.897,159.711,449.824,164.378,448.779,168.929z"
              fill="currentColor"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M292.631,310.526c10.831,0,19.644-8.812,19.644-19.644v-12.088c0-28.268-21.084-51.702-48.354-55.4v-13.101
              c0-10.831-8.812-19.644-19.644-19.644c-10.831,0-19.644,8.812-19.644,19.644v14.101c-27.997,6.245-48.354,31.134-48.354,60.444
              c0,34.161,27.792,61.953,61.953,61.953h12.088c12.497,0,22.666,10.167,22.666,22.666s-10.168,22.666-22.666,22.666h-18.133
              c-9.166,0-16.622-7.457-16.622-16.622c0-10.831-8.812-19.644-19.644-19.644c-10.831,0-19.644,8.812-19.644,19.644
              c0,28.268,21.084,51.702,48.354,55.4v13.101c0,10.831,8.812,19.644,19.644,19.644c10.831,0,19.644-8.812,19.644-19.644v-14.101
              c27.997-6.245,48.354-31.134,48.354-60.444c0-34.161-27.792-61.953-61.953-61.953h-12.088c-12.497,0-22.666-10.167-22.666-22.666
              s10.168-22.666,22.666-22.666h18.133c9.166,0,16.622,7.457,16.622,16.622v12.088C272.987,301.713,281.8,310.526,292.631,310.526z
               M256.366,247.062h-18.133c-20.83,0-37.776,16.946-37.776,37.776c0,20.83,16.946,37.776,37.776,37.776h12.088
              c25.83,0,46.843,21.014,46.843,46.843c0,23.899-17.899,43.908-41.632,46.545c-3.826,0.425-6.721,3.659-6.721,7.509v20.491
              c0,2.499-2.034,4.533-4.533,4.533s-4.533-2.034-4.533-4.533v-20.147c0-4.173-3.382-7.555-7.555-7.555
              c-22.497,0-40.798-18.302-40.798-40.798c0-2.499,2.034-4.533,4.533-4.533s4.533,2.034,4.533,4.533
              c0,17.497,14.235,31.732,31.732,31.732h18.133c20.83,0,37.776-16.946,37.776-37.776s-16.946-37.776-37.776-37.776h-12.088
              c-25.83,0-46.843-21.014-46.843-46.843c0-23.899,17.899-43.908,41.632-46.545c3.826-0.425,6.721-3.659,6.721-7.509v-20.491
              c0-2.499,2.034-4.533,4.533-4.533s4.533,2.034,4.533,4.533v20.147c0,4.173,3.382,7.555,7.555,7.555
              c22.497,0,40.798,18.302,40.798,40.798v12.088c0,2.499-2.034,4.533-4.533,4.533s-4.533-2.034-4.533-4.533v-12.088
              C288.098,261.297,273.863,247.062,256.366,247.062z"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    ),
    viewBox: '0 0 511.999 511.999',
  }),
  time: createIcon({
    path: (
      <svg>
        <g>
          <g>
            <path
              d="M256,0C114.848,0,0,114.848,0,256s114.848,256,256,256s256-114.848,256-256S397.152,0,256,0z M256,480
              C132.48,480,32,379.52,32,256S132.48,32,256,32s224,100.48,224,224S379.52,480,256,480z"
              fill="currentColor"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M256,240c-26.016,0-48-14.656-48-32s21.984-32,48-32c14.72,0,28.352,4.608,37.376,12.608
              c6.592,5.856,16.672,5.312,22.592-1.344c5.888-6.592,5.28-16.704-1.344-22.592c-11.2-9.952-26.24-16.672-42.624-19.328V128
              c0-8.832-7.168-16-16-16c-8.832,0-16,7.168-16,16v17.28c-36.48,5.952-64,31.808-64,62.72c0,35.296,35.904,64,80,64
              c26.016,0,48,14.656,48,32s-21.984,32-48,32c-14.72,0-28.352-4.608-37.376-12.608c-6.592-5.888-16.704-5.312-22.592,1.344
              c-5.888,6.592-5.28,16.704,1.344,22.592c11.2,9.984,26.24,16.672,42.624,19.36V384c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16
              v-17.28c36.48-5.952,64-31.808,64-62.72C336,268.704,300.096,240,256,240z"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    ),
    viewBox: '0 0 512 512',
  }),
  dollar: createIcon({
    path: (
      <svg>
        <g>
          <g>
            <path
              d="M256,0C114.848,0,0,114.848,0,256s114.848,256,256,256s256-114.848,256-256S397.152,0,256,0z M256,480
              C132.48,480,32,379.52,32,256S132.48,32,256,32s224,100.48,224,224S379.52,480,256,480z"
              fill="currentColor"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M256,240c-26.016,0-48-14.656-48-32s21.984-32,48-32c14.72,0,28.352,4.608,37.376,12.608
              c6.592,5.856,16.672,5.312,22.592-1.344c5.888-6.592,5.28-16.704-1.344-22.592c-11.2-9.952-26.24-16.672-42.624-19.328V128
              c0-8.832-7.168-16-16-16c-8.832,0-16,7.168-16,16v17.28c-36.48,5.952-64,31.808-64,62.72c0,35.296,35.904,64,80,64
              c26.016,0,48,14.656,48,32s-21.984,32-48,32c-14.72,0-28.352-4.608-37.376-12.608c-6.592-5.888-16.704-5.312-22.592,1.344
              c-5.888,6.592-5.28,16.704,1.344,22.592c11.2,9.984,26.24,16.672,42.624,19.36V384c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16
              v-17.28c36.48-5.952,64-31.808,64-62.72C336,268.704,300.096,240,256,240z"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    ),
    viewBox: '0 0 512 512',
  }),
  likeIcon: createIcon({
    path: (
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.4018 0.00645721C9.95993 0.0550102 9.5413 0.370605 9.30873 0.831858C9.00639 1.46305 6.7737 6.14841 6.33181 6.60966C5.88993 7.07092 5.3085 7.2894 4.6573 7.2894V17H12.7973C13.2857 17 13.7043 16.6844 13.8904 16.2474C13.8904 16.2474 16.2859 9.18297 16.2859 8.50323C16.2859 7.82349 15.7742 7.2894 15.123 7.2894H11.6344C10.9832 7.2894 10.4716 6.68249 10.4716 6.07558C10.4716 5.46867 11.3786 2.23989 11.5647 1.60871C11.7507 0.977517 11.4484 0.297775 10.8437 0.0792867C10.6809 0.0307337 10.5646 -0.0178193 10.4018 0.00645721ZM0.00585938 7.2894V17H2.33158V7.2894H0.00585938Z"
          fill="#0F61D6"
        />
      </svg>
    ),
    viewBox: '0 0 17 17',
  }),
};

const appTheme = extendTheme(
  {
    customThemes: {
      yellow: yellowTheme,
      blue: defaultTheme,
      red: redTheme,
    },
    colors: {
      ...proTheme.colors,
      brand: proTheme.colors.blue,
      white: {
        500: '#fff',
      },
    },
    icons: {
      ...(<Icon />),
      ...customIcons,
    },
    fonts: {
      heading: getFontsByPartner(),
      body: getFontsByPartner(),
    },
    textStyles: {
      title: {
        fontSize: ['36px', '32px', '52px', '64px'],
        fontWeight: '400',
        lineHeight: '140%',
      },
      subtitle: {
        fontSize: ['16px', '22px'],
        fontWeight: '300',
      },
    },
    global: {
      'html, body': {
        cursor: 'default',
      },
      "a, button, [role='button']": {
        cursor: 'pointer',
      },
    },
  },
  proTheme,
  breakpoints
);

const customTheme = appTheme.customThemes[getThemeByPartner()];

export const theme = Object.assign({}, appTheme, customTheme);
