import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
/* eslint-disable @next/next/no-img-element */
import React, { RefObject } from 'react';
import { getFaintBgByPartner, getPryBtnColorByPartner } from './partner-config';

import Link from 'next/link';
import dynamic from 'next/dynamic';
import { getLandingImageByPartner } from '../../utils/partnerTheme';

const Footer = dynamic(() => import('./footer'));
const Navbar = dynamic(() => import('./navbar'));
const Faq = dynamic(() => import('../../pages/faq'));
const ProtectionPlan = dynamic(() => import('../../pages/bap'));
const RequestFixPage = dynamic(() => import('../../pages/request-fix'));

const lpViews = process.env.NEXT_PUBLIC_LP_VIEWS;

const IndexPage = () => {
  const buyPlanRef = React.useRef<HTMLDivElement>(null);
  const requestFixRef = React.useRef<HTMLDivElement>(null);
  const faqRef = React.useRef<HTMLDivElement>(null);

  const handleNavigate = (ref: RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box className="body">
      {/* navbar */}
      <Navbar
        navigate={handleNavigate}
        buyPlanRef={buyPlanRef}
        requestFixRef={requestFixRef}
      />
      {/* section 1 */}
      {lpViews?.includes('one') && (
        <Box
          as="section"
          className="section main-section"
          backgroundColor="#fff"
        >
          <Box className="container---main">
            <Box
              className="boxed-image-section increase-bottom-padding"
              pb={0}
              maxW="1400px"
              mx="auto"
              bg={getFaintBgByPartner()}
            >
              <Box className="boxed-image-section-content-wrapper">
                <Box
                  className="container---m"
                  ml={['unset', 'unset', '50px']}
                  pr={['initial', '100px']}
                >
                  <Box className="hero-cta-contents">
                    <Box className="large-cta-box-text align-left">
                      <Heading
                        className="display-heading hero-cta-heading"
                        fontSize={['54px', '80px', '87px']}
                        lineHeight={['0.95em']}
                      >
                        Pay Zero Naira every time you need to repair your device
                      </Heading>
                      <Box className="container---s"></Box>
                    </Box>
                    <Box className="text-block-21">
                      Get up to N120, 000 worth of FREE repairs with a plan as
                      low as N820 monthly.
                    </Box>
                    <Box className="horizontal-buttons">
                      <Link
                        href="/request-fix/category"
                        className="button w-inline-block"
                        style={{
                          backgroundColor: getPryBtnColorByPartner(),
                        }}
                      >
                        <Box className="button-text text-primary-1b">
                          Request fix
                        </Box>
                      </Link>
                      <Link href="/device-care/plan">
                        <Box
                          className="button-text protect-button"
                          borderColor={getPryBtnColorByPartner()}
                          color={getPryBtnColorByPartner()}
                        >
                          Protect my device
                        </Box>
                      </Link>
                    </Box>
                    <Box className="app-store-reviews-horizontal stack-on-mobile">
                      <Box className="app-store-reviews-text centered-on-mobile">
                        <Box className="stars" mx="auto">
                          <img
                            src="images/5-star.webp"
                            loading="lazy"
                            style={{ width: 200 }}
                            alt="5 stars"
                            // className="image-7"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <img
                src={getLandingImageByPartner().src}
                alt="landing"
                className="boxed-image-section-image landing-image"
              />
            </Box>
          </Box>
        </Box>
      )}
      {/* Trial Campaign Section */}
      <Box as="section" py={[10, 16, 20]} px={[2, 8]}>
        <VStack spacing={4} mb={12} textAlign={'center'}>
          <Heading
            as="h2"
            fontSize={['4xl', '5xl', '6xl']}
            fontWeight="bold"
            lineHeight="1.2"
          >
            Experience{' '}
            <Text as="span" color="primary">
              30 Days
            </Text>{' '}
            of Premium Coverage
            <br />
            Absolutely{' '}
            <Text as="span" color="primary">
              Free
            </Text>
          </Heading>
        </VStack>

        <Box bg="primary" borderRadius="xl" p={[6, 8, 12]} color="white">
          <Container maxW="6xl">
            <VStack spacing={2} mb={12} textAlign="center">
              <Heading
                as="h3"
                fontSize={['2xl', '3xl', '4xl']}
                lineHeight="1.2"
                fontWeight="bold"
              >
                Try Our Premium Device Protection – Free for 30 Days!
              </Heading>
              <Text fontSize={['md', 'lg']} maxW="3xl">
                Enjoy ₦100,000 coverage for accidental damage, repairs, and
                more, risk-free for 30 days.
              </Text>
            </VStack>

            {/* Subsection 2: Three Cards */}
            <Stack
              direction={['column', 'column', 'row']}
              spacing={8}
              mb={12}
              justify="center"
            >
              <Box
                bg="white"
                p={6}
                px={10}
                borderRadius="lg"
                flex="1"
                color="gray.800"
                textAlign="center"
              >
                <Box
                  mb={4}
                  fontSize="2xl"
                  bg="blue.50"
                  w="12"
                  h="12"
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mx="auto"
                >
                  🛡️
                </Box>
                <Text>Comprehensive Coverage</Text>
                <Text fontWeight="bold" as="h4" fontSize="xl" mt={4}>
                  Peace of mind for your devices.
                </Text>
              </Box>

              <Box
                bg="white"
                p={6}
                px={10}
                borderRadius="lg"
                flex="1"
                color="gray.800"
                textAlign="center"
              >
                <Box
                  mb={4}
                  fontSize="2xl"
                  bg="blue.50"
                  w="12"
                  h="12"
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mx="auto"
                >
                  💳
                </Box>
                <Text>No Charges During Trial</Text>
                <Text fontWeight="bold" as="h4" fontSize="xl" mt={4}>
                  Card required, but no charges upfront.
                </Text>
              </Box>

              <Box
                bg="white"
                p={6}
                px={10}
                borderRadius="lg"
                flex="1"
                color="gray.800"
                textAlign="center"
              >
                <Box
                  mb={4}
                  fontSize="2xl"
                  bg="blue.50"
                  w="12"
                  h="12"
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mx="auto"
                >
                  🔄
                </Box>
                <Text>Seamless Transition</Text>
                <Text fontWeight="bold" as="h4" fontSize="xl" mt={4}>
                  Easily upgrade or cancel anytime.
                </Text>
              </Box>
            </Stack>

            <Flex justify="center">
              <Button
                onClick={() => handleNavigate(buyPlanRef)}
                size="lg"
                bg="white"
                color="primary"
                _hover={{ bg: 'gray.100' }}
                px={12}
                py={8}
                fontSize="lg"
                fontWeight="bold"
                rounded="full"
              >
                Start 30 days Free trial
              </Button>
            </Flex>
          </Container>
        </Box>
      </Box>
      {/* section 2 */}
      {lpViews?.includes('two') && (
        <Box
          as="section"
          className="section repair-section"
          maxW="1400px"
          mx="auto"
        >
          <Box className="container---main">
            <Box className="product-features-section">
              <Heading
                className="heading-one text-center"
                fontWeight="700"
                mb={10}
              >
                Everything you need to stay protected
              </Heading>
              <Box className="product-features-grid">
                <Box
                  id="w-node-_39964da8-3045-47d6-292c-3b3e4d7a10ea-df636a8f"
                  className="product-features-column"
                >
                  <Box
                    data-w-id="c9a3038f-81c6-96c5-9bf5-ee6d59d674bc"
                    className="product-feature-box"
                    bg="card2"
                  >
                    <Box className="widget transaction">
                      <Box className="widget-transaction-title">
                        <Box className="text-bold">Scheduled Pickup</Box>
                      </Box>
                      <Box className="widget pay-widget">
                        <Box className="widget-transaction-recipient">
                          <img
                            src="images/images.jpeg"
                            alt=""
                            className="widget-transaction-author"
                          />
                          <Box className="text-block-45">
                            Apple iPhone 13 <br />
                            Broken Screen
                          </Box>
                        </Box>
                        <Box className="widget-pay-text">
                          <Box className="muted">Pickup Time</Box>
                          <Box className="heading-three">
                            Tue 3rd March
                            <br />
                            2:00 pm
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="container---xs">
                      <Box className="product-feature-box-text">
                        <Box className="heading-three">Request Repairs</Box>
                        <Box className="text-block-17">
                          Manufacturer-Approved fast, affordable and reliable
                          repairs of your devices from the comfort of your home,
                          office, and wherever you are.
                        </Box>
                        <Link
                          href="/request-fix/category"
                          className="button w-inline-block"
                          style={{
                            backgroundColor: getPryBtnColorByPartner(),
                          }}
                        >
                          <Box className="button-text text-primary-1b">
                            Request a fix
                          </Box>
                          <Box className="button-effect bg-white"></Box>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  id="w-node-_91215528-acc9-924a-2663-2a8ed490a256-df636a8f"
                  className="product-features-column offset"
                >
                  <Box
                    data-w-id="ae276f61-0e89-76e5-8e4e-104e25a3514c"
                    className="product-feature-box"
                    bg="card1"
                  >
                    <Box className="widget savings-goals">
                      <Box className="heading-four">My Devices</Box>
                      <Box className="widget-goal-wrapper">
                        <Box className="small-text text-bold">
                          📱Collins&#x27;s iPhone
                        </Box>
                        <Box className="widget-text-pair">
                          <Box className="small-text muted">
                            iPhone 13 Pro Max
                          </Box>
                          <Box className="small-text">Coverage Ongoing</Box>
                        </Box>
                      </Box>
                      <Box className="widget-goal-wrapper">
                        <Box className="small-text text-bold">
                          ⌚️Collins&#x27;s Apple Watch
                        </Box>
                        <Box className="widget-text-pair">
                          <Box className="small-text muted">
                            Apple Watch Series 7
                          </Box>
                          <Box className="small-text">Coverage Expired</Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="container---xs">
                      <Box className="product-feature-box-text">
                        <Box className="heading-three">Protection Plans</Box>
                        <Box className="text-block-16">
                          Enjoy zero repairs cost when you purchase a Device
                          Protection Plan. Avoid unexpected costs tomorrow by
                          purchasing a Protection Plan today
                        </Box>
                      </Box>
                    </Box>
                    <Link href="/device-care/plan">
                      <Box
                        className="button-text protect-button"
                        borderColor={getPryBtnColorByPartner()}
                        color={getPryBtnColorByPartner()}
                      >
                        Protect my device
                      </Box>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {/* section 3 */}
      {lpViews?.includes('three') && (
        <Box
          as="section"
          className="section repair-section black-bg"
          bg={['unset', 'blackAlpha.900']}
          color={['black', 'white']}
          maxW="1400px"
          mx="auto"
        >
          <Box className="container---main">
            <Flex
              gap={5}
              flexDir={['column', 'row']}
              justifyContent={['space-between']}
            >
              <Box>
                <Text className="heading-four" color={['black', 'white']}>
                  Get it fixed fast
                </Text>
                <Text className="text-block-8">
                  Quickest pick up,repair and delivery service in the country!
                </Text>
              </Box>
              <Box>
                <Text className="heading-four" color={['black', 'white']}>
                  Repair Warranty
                </Text>
                <Text className="text-block-9">
                  Manufacturer Approved Warranty Repairs for your devices!
                </Text>
              </Box>
              <Box>
                <Text className="heading-four" color={['black', 'white']}>
                  Affordable Options
                </Text>
                <Text>
                  Fix your broken devices without breaking your bank account!
                </Text>
              </Box>
              <Box>
                <Text className="heading-four" color={['black', 'white']}>
                  Convenient Repairs
                </Text>
                <Text className="text-block-11">
                  World leading Door-to-Door service! Every service you need at
                  your fingertips through our app!
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
      )}
      {/* buy plan */}
      <ProtectionPlan sectionRef={buyPlanRef} />

      {/* request fix */}
      <RequestFixPage sectionRef={requestFixRef} />
      {/* faq */}
      <Faq sectionRef={faqRef} />
      {/* footer */}
      <Footer
        navigate={handleNavigate}
        buyPlanRef={buyPlanRef}
        requestFixRef={requestFixRef}
        faqRef={faqRef}
      />
    </Box>
  );
};

export default IndexPage;
